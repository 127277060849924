const siteAssets = {
    ssmfi: {
        title: "Shepards Staff",
        logo: require('../assets/images/ss/logo.png'),
        favicon: require('../assets/images/ss/favicon.jpg'),
        background: require('../assets/images/ss/background.jpg'),
    },
    kinetix: {
        title: "Kinetix",
        logo: require('../assets/images/kinetix/logo.png'),
        background: require('../assets/images/kinetix/background.jpg'),
    }
};

const helpers = {

    getSite: function() {
        const url = window.location.host;
        return url;
    },

    getAsset: function(asset){
        const { REACT_APP_SITE: site = null } = process.env;

        if (!site || !(site in siteAssets)) throw `Unknown site: "${site}"`;
        return siteAssets[site][asset];
    }
}

export default helpers;