import React, {Component} from 'react';
import {connect} from 'react-redux';
import Actions from '../../actions';
import _ from 'lodash';
import {
    RESET_PASSWORD_EMAIL_SUCCESS
} from '../../actions/types';
import Input from '../fields/input';
import RaisedButton from 'material-ui/RaisedButton';
import CommunicationEmail from 'material-ui/svg-icons/communication/email';
import SiteHelper from '../../utilities/site-manager-helper';

class ForgotPassword extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
        }
    }

    resetPasswordEmail(event) {
        const that = this;
        event.preventDefault();
        const form = document.getElementById('reset-password-email-form');
        let formData = new FormData(form);
        this.props.dispatch(Actions.requestResetPasswordEmail(formData))
            .then(function (result) {
                if (result.type === RESET_PASSWORD_EMAIL_SUCCESS) {
                    that.props.dispatch(Actions.newMessage('Email Sent!'));
                } else {
                    that.props.dispatch(Actions.newError('Something went wrong, please try again.'));
                }
            })
    }

    handleChange(event) {
        this.setState({email: event});
    }


    render() {
        return (
            <div className="login-view" style={{ backgroundImage: "url(" + SiteHelper.getAsset('background') + ")"}}>
                <div className="forgot-password-container">
                    <form onSubmit={(event) => this.resetPasswordEmail(event)} id='reset-password-email-form'
                          encType="multipart/form-data">
                        <h3 className="forgot-password-text">Forgot Your Password?</h3>
                        <p className="forgot-password-desc-text">Don’t worry! Type your email to reset your password.</p>
                        <div className="forgot-password-form-inputs">
                            <Input
                                type="email"
                                id="email"
                                fieldIcon={<CommunicationEmail/>}
                                errors={this.props.auth}
                                onChange={(event) => this.handleChange(event)}
                                value={this.state.email}
                                placeholder="Email"
                            />
                        </div>
                        
                            <div
                                className='error'>{_.get(this.props.auth, 'error.message') ? this.props.auth.error.message : null}
                            </div>
                            <br/>
                            <div className="forgot-pass-button">
                                <RaisedButton className="forgot-password-submit" primary={true} label="Send Verification" type="submit"
                                               onClick={(event) => this.resetPasswordEmail(event)}/>
                           </div>
                        
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        auth: state.rootReducer.auth
    })
)(ForgotPassword);